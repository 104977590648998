import React, { useEffect, useState } from 'react'


const Flipcard = ({ front, back ,yinyang,attributes}) => {

    return (
        <>
            <div className={'flip-box '+ yinyang}>
                <div className={'flip-front '+attributes}>
                    <div className='inner'>
                        {front}
                    </div>
                </div>
                <div className={'flip-back '+attributes}>
                    <div className='inner'>
                        <img src={back} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default Flipcard