import React, {useEffect, useState} from 'react'
import {isMobile} from "is-mobile";



const Setting = () => {
    const is_mobile = isMobile();
    const [tab,setTab] = useState(0);
    const showTab = (event)=> {
        console.log(event.target.id,tab);
        const id = event.target.id;
        if (tab != id) {
            setTab(id);
        } else {
            setTab(0);
        }
    }

  return (
    <div className={is_mobile ? "about-main ph-main-mobile" : "about-main"}>
        <div className={is_mobile ? "development-title setting-title-mobile" : "development-title"}>
            Setting Collection
        </div>
        <div className={is_mobile ? "setting-desc setting-desc-mobile" : "setting-desc"}>
            <div>
                Combining the Birth Time-location Determining Destiny Theory, Wuxing Theory and YinYang Theory, we provide a special way for people to get Unicorn's on-chain DNA, which is composed of 9 elements: Spirit Body (Wuxing), YinYang, Talent, Horn, Wings, Tail, Horseshoes, Destiny Number, Lucky Number. To be specific, Wuxing and Yinyang together determine the spiritual body and horn of the unicorn.
            </div>

        </div>
        <div className={is_mobile ? "setting-middle setting-middle-mobile" : "setting-middle"}>
            <div className={is_mobile ? "setting-middle-child setting-middle-child-mobile" : "setting-middle-child"}>
                <div className={is_mobile ? "setting-middle-child-title setting-middle-child-title-mobile" : "setting-middle-child-title"}>
                    YingYang
                </div>
                <div className={is_mobile ? "setting-middle-child-desc setting-middle-child-desc-mobile" : "setting-middle-child-desc"}>
                    YinYang determines the length of the Unicorn's hair and the font color of the text picture of Unicorn
                </div>
            </div>
            <div className={is_mobile ? "setting-middle-child setting-middle-child-mobile" : "setting-middle-child"}>
                <div className={is_mobile ? "setting-middle-child-title setting-middle-child-title-mobile" : "setting-middle-child-title"}>
                    WuXing
                </div>
                <div className={is_mobile ? "setting-middle-child-desc setting-middle-child-desc-mobile" : "setting-middle-child-desc"}>
                    Wuxing determines unicorn’s spiritual body and the background color of the text picture of Unicorn
                </div>
            </div>

        </div>
        <div className={is_mobile ? "setting-detail setting-detail-mobile" : "setting-detail"}>
            <div className="setting-detail-child-main">
                <div className="flex setting-detail-child">
                    <div className="setting-detail-child-left">
                        <div className="setting-detail-title">
                            Spiritual Body
                        </div>
                        <div className="setting-detail-desc">
                            Wuxing decides the spiritual body of Unicorns
                        </div>
                    </div>
                    <img
                        onClick={showTab}
                        id="1"
                        className="setting-detail-img" src={tab == 1 ? "up.png" : "xiala.png"} alt=""/>
                </div>
                {tab == 1 && (<div className="setting-detail-child-desc">
                    <div>
                        The spirit body is designed into five types: Wood, Blue Body
                    </div>
                    <div>
                        Fire, Red Body
                    </div>
                    <div>
                        Earth, Colorful Body
                    </div>
                    <div>
                        Metal, Golden Body
                    </div>
                    <div>
                        Water, White Body
                    </div>
                </div>)}

            </div>

            <div className="setting-detail-child-main">
                <div className="flex setting-detail-child">
                    <div className="setting-detail-child-left">
                        <div className="setting-detail-title">
                            Yin Yang
                        </div>
                        <div className="setting-detail-desc">
                            YinYang decide the basic temperament of Unicorns
                        </div>
                    </div>
                    <img
                        onClick={showTab}
                        id="2"
                        className="setting-detail-img" src={tab == 2 ? "up.png" : "xiala.png"} alt=""/>
                </div>
                {tab == 2 && (<div className="setting-detail-child-desc">
                    <div>
                        YinYang is designed into two types
                    </div>
                    <div>
                        Yang, Short Hair
                    </div>
                    <div>
                        Yin, Long hair
                    </div>
                </div>)}
            </div>
            <div className="setting-detail-child-main">
                <div className="flex setting-detail-child">
                    <div className="setting-detail-child-left">
                        <div className="setting-detail-title">
                            Talents
                        </div>
                        <div className="setting-detail-desc">
                            Talents are co-decided by Wuxing and Yinyang, representing their skills
                        </div>
                    </div>
                    <img
                        onClick={showTab}
                        id="3"
                        className="setting-detail-img" src={tab == 3 ? "up.png" : "xiala.png"} alt=""/>
                </div>
                {tab == 3 && (<div className="setting-detail-child-desc">
                    <div>
                        YinYang and Wuxing decide the talents:
                    </div>
                    <div>
                        Revival (Yang Wood)
                    </div>
                    <div>
                        Death (Yin Wood)
                    </div>
                    <div>
                        Curse (Yang Fire)
                    </div>
                    <div>
                        Prophet (Yin Fire)
                    </div>
                    <div>
                        Crown (Yang Earth)
                    </div>
                    <div>
                        Hermit (Yin Earth)
                    </div>
                    <div>
                        Attack (Yang Metal)
                    </div>
                    <div>
                        Guard (Yin Metal)
                    </div>
                    <div>
                        Summon (Yang Water)
                    </div>
                    <div>
                        Forget (Yin Water)
                    </div>
                </div>)}
            </div>

            <div className="setting-detail-child-main">
                <div className="flex setting-detail-child">
                    <div className="setting-detail-child-left">
                        <div className="setting-detail-title">
                            Horn
                        </div>
                        <div className="setting-detail-desc">
                            Horns represent the source of spiritual power of Unicorns
                        </div>
                    </div>
                    <img
                        onClick={showTab}
                        id="4"
                        className="setting-detail-img" src={tab == 4 ? "up.png" : "xiala.png"} alt=""/>
                </div>
                {tab == 4 && (<div className="setting-detail-child-desc">
                    <div>
                        The horn is corresponds to the source of spiritual power of Unicorn, and it’s also decided by YinYang and Wuxing. There are ten types:
                    </div>
                    <div>
                        Shaman Horn (Yang Wood)
                    </div>
                    <div>
                        Hel Horn (Yin Wood)
                    </div>
                    <div>
                        Necromancer Horn (Yang Fire)
                    </div>
                    <div>
                        Sibyl Horn (Yin Fire)
                    </div>
                    <div>
                        Caesar Horn (Yang Earth)
                    </div>
                    <div>
                        Lao Tsu Horn (Yin Earth)
                    </div>
                    <div>
                        Warrior Horn (Yang Metal)
                    </div>
                    <div>
                        Praetorian Horn (Yin Metal)
                    </div>
                    <div>
                        Bard Horn (Yang Water)
                    </div>
                    <div>
                        Lethe Horn (Yin Water)
                    </div>
                </div>)}
            </div>

            <div className="setting-detail-child-main">
                <div className="flex setting-detail-child">
                    <div className="setting-detail-child-left">
                        <div className="setting-detail-title">
                            Wings
                        </div>
                        <div className="setting-detail-desc">
                            Unicorns have their dream wings but usually they can’t get, especially angel wings.
                        </div>
                    </div>
                    <img
                        onClick={showTab}
                        id="5"
                        className="setting-detail-img" src={tab == 5 ? "up.png" : "xiala.png"} alt=""/>
                </div>
                {tab == 5 && (<div className="setting-detail-child-desc">
                    <div>
                        There are eleven tapes of wings. Unicorns with the Angel Wings can be free from the setting of YinYang and Wuxing.
                    </div>
                    <div>                        Wind Wings (Yang Wood's best choice)
                    </div>
                    <div>                        Night Shadow Wings (The best choice for Yin Wood)
                    </div>
                    <div>                        Sun Wings (The best choice for Yang Fire's)
                    </div>
                    <div>                        Lightning Wings (Yin Fire best choice)
                    </div>
                    <div>                        Golden Wings (Yang Gold's best choice)
                    </div>
                    <div>                        Star Wings (The best choice for Yin Gold)
                    </div>
                    <div>                        Cloud Wings (The best choice for Yin Earth)
                    </div>
                    <div>                        Morning Glow Wings (Yang Earth's best choice)
                    </div>
                    <div>                        Spring Wings (Yang Water's best choice)
                    </div>
                    <div>                        Moon Wings (The best choice for Yin Water)
                    </div>
                    <div>                        Angel Wings (The best choice for all unicorns)
                    </div>

                </div>)}
            </div>

            <div className="setting-detail-child-main">
                <div className="flex setting-detail-child">
                    <div className="setting-detail-child-left">
                        <div className="setting-detail-title">
                            Tail
                        </div>
                        <div className="setting-detail-desc">
                            Affecting Unicorns‘ personalities and consciousness level
                        </div>
                    </div>
                    <img
                        onClick={showTab}
                        id="6"
                        className="setting-detail-img" src={tab == 6 ? "up.png" : "xiala.png"} alt=""/>
                </div>
                {tab == 6 && (<div className="setting-detail-child-desc">
                    <div>
                        There are nine types of tails. Unicorns with Socrate tail, Bumbledore tail, Venus tail or Gaia tail have a higher level of consciousness.
                    </div>
                    <div>                        Meteor Tail
                    </div>
                    <div>                        Rainbow Tail
                    </div>
                    <div>                        Willow Tail
                    </div>
                    <div>                        Phoenix Tail
                    </div>
                    <div>                        Sunset Shadow Tail
                    </div>
                    <div>                        Socrates Tail
                    </div>
                    <div>                        Dumbledore Tail
                    </div>
                    <div>                        Venus Tail
                    </div>
                    <div>                        Gaia Tail
                    </div>

                </div>)}
            </div>

            <div className="setting-detail-child-main">
                <div className="flex setting-detail-child">
                    <div className="setting-detail-child-left">
                        <div className="setting-detail-title">
                            Horseshoes
                        </div>
                        <div className="setting-detail-desc">
                            the most boring traits unicorns have
                        </div>
                    </div>
                    <img
                        onClick={showTab}
                        id="7"
                        className="setting-detail-img" src={tab == 7 ? "up.png" : "xiala.png"} alt=""/>
                </div>
                {tab == 7 && (<div className="setting-detail-child-desc">
                    <div>
                        There are seven types of horseshoes:
                    </div>
                    <div>                        Ice Horseshoes
                    </div>
                    <div>                        Crystal Horseshoes
                    </div>
                    <div>                        Maple Horseshoes
                    </div>
                    <div>                        Flame Horseshoes
                    </div>
                    <div>                        Thunder Horseshoes
                    </div>
                    <div>                        Lotus Horseshoes
                    </div>
                    <div>                        Silver Horseshoes
                    </div>
                </div>)}
            </div>

            <div className="setting-detail-child-main">
                <div className="flex setting-detail-child">
                    <div className="setting-detail-child-left">
                        <div className="setting-detail-title">
                            Lifespan
                        </div>
                        <div className="setting-detail-desc">
                            Determining the destiny
                        </div>
                    </div>
                    <img
                        onClick={showTab}
                        id="8"
                        className="setting-detail-img" src={tab == 8 ? "up.png" : "xiala.png"} alt=""/>
                </div>
                {tab == 8 && (<div className="setting-detail-child-desc">
                    <div>
                        Every unicorn has a Destiny Number, which is set as a random number between 50000 and 100000. In different worlds, destiny number can have different interpretations. For example: you can use the destiny number as the length of time unicorns can survive in the wolrd you create. It decides some key fate choices, while it can be used very flexibly.
                    </div>

                </div>)}
            </div>

            <div className="setting-detail-child-main1">
                <div className="flex setting-detail-child">
                    <div className="setting-detail-child-left">
                        <div className="setting-detail-title">
                            Lucky Number
                        </div>
                        <div className="setting-detail-desc">
                            Affecting the good luck
                        </div>
                    </div>
                    <img
                        onClick={showTab}
                        id="9"
                        className="setting-detail-img" src={tab == 9 ? "up.png" : "xiala.png"} alt=""/>
                </div>
                {tab == 9 && (<div className="setting-detail-child-desc">
                    <div>
                        The lucky number is different from destiny number, which is set as a random number between 1 to 49. The lucky number corresponds to good luck at the moment of change. Different from the destiny number, it is not deterministic but only increasing the probability of good luck. It is rare to see different unicorns with the same lifespan, but you can see them have the same lucky number.
                    </div>

                </div>)}
            </div>
        </div>



        <div className={is_mobile ? "setting-bottom setting-bottom-mobile" : "setting-bottom"}>
            <div className="setting-bottom-title">
                Magic Unicorns
            </div>
                <div className="setting-bottom-main">
                    <div className="bottom-child">
                        <img style={{width:'100%',height:'100%'}} src="c1.png" alt=""/>
                    </div>
                    <div className="bottom-child">
                        <img style={{width:'100%',height:'100%'}} src="c2.png" alt=""/>
                    </div>
                    <div className="bottom-child">
                        <img style={{width:'100%',height:'100%'}} src="c3.png" alt=""/>
                    </div>
                    <div className="bottom-child">
                        <img style={{width:'100%',height:'100%'}} src="c4.png" alt=""/>
                    </div>

                    <div className="bottom-child">
                        <img style={{width:'100%',height:'100%'}} src="c6.png" alt=""/>
                    </div>
                    <div className="bottom-child">
                        <img style={{width:'100%',height:'100%'}} src="c7.png" alt=""/>
                    </div>
                    <div className="bottom-child">
                        <img style={{width:'100%',height:'100%'}} src={is_mobile ? "c5.png": "c8.png"} alt=""/>
                    </div>
                    <div className="bottom-child">
                        <img style={{width:'100%',height:'100%'}} src={is_mobile ? "c10.png" : "c9.png"} alt=""/>
                    </div>
                    {!is_mobile && <div className="bottom-child"></div>}
                    <div className="bottom-child">
                        <img style={{width:'100%',height:'100%'}} src={is_mobile ? "c8.png" : "c5.png"} alt=""/>
                    </div>
                    <div className="bottom-child">
                        <img style={{width:'100%',height:'100%'}} src={is_mobile ? "c9.png" : "c10.png"} alt=""/>
                    </div>
                    <div className="bottom-child"></div>
                    {is_mobile && <div className="bottom-child"></div>}
                </div>

        </div>
    </div>

  )
}

export default Setting