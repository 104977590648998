import React, {useEffect, useState} from 'react'
import {isMobile} from "is-mobile";



const Community = () => {
    const is_mobile = isMobile();


  return (
    <div className="about-main">
        <div className={is_mobile ? "community-title community-title-mobile" : "community-title"}>
            Community
        </div>
        <div className={is_mobile ? "community-desc community-desc-mobile" : "community-desc"}>
            <div>
                As a community-driven project, Unicorn Dobs have been completely airdropped to the community according to an open rule from the very beginning. There is no reserved portion for project initiators, nor are there any transaction fees collected from secondary markets.
            </div>
            <div>
                As a completely non-profit project, Unicorn’s birth and development are made possible by a group of developers, creators and supporters interested in the Dob concept.
            </div>
            <div>
                Community means everything to Unicorn. There should be a more community-oriented governance model, allowing everyone to use Unicorn's official website, official channels, and other public resources reasonably to promote Unicorn's development.
            </div>
        </div>
        <div className={is_mobile ? "community-title community-title1 community-title-mobile" : "community-title community-title1"}>
            Copyright
        </div>
        <div className={is_mobile ? "community-desc community-desc-mobile" : "community-desc"}>
            <div>
                Unicorn is a CC0 project and also a non-profit project. Following the open-source spirit, everyone can expand this world based on the existing Unicorn settings and create new products. To be specific, people should bear all responsibility, including reputation and business responsibility when they build commercial or non-profit projects in the meantime.
            </div>
        </div>
    </div>

  )
}

export default Community