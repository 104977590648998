import React, {useEffect, useState} from 'react'
import {isMobile} from "is-mobile";



const About = () => {
    const is_mobile = isMobile();


  return (
    <div className="about-main">
        <div className={is_mobile ? "about-title about-title-mobile" : "about-title"}>
            About Unicorn
        </div>
        <div className={is_mobile ? "about-desc about-desc-mobile" : "about-desc"}>
            <div>Where is Unicorn? No one has ever seen it.</div>
            <div>
                In the poet's singing, in the girl's forgetful memory, in the prophet's blessing, in the mage's curse.
            </div>
            <div>
                But Unicorn is here... Here on-chain, a magic world will unfold.
            </div>
            <div>
                And you, and the time and space on this chain, will determine Unicorns’ DNA and fate.
            </div>
            <div>
                Let’s start the wheel of fortune together. It's not just magic, but fact.
            </div>
            <div>
                Good luck to us all.
            </div>
        </div>
    </div>

  )
}

export default About