export default {
  account: null,
  public_key: null,
  loading: null,
  type: null,
  joyid_account:null,
  joyid_account1:null,
  popup: false,
  joyid_sign_msg: '',
  claim_all_num: 0,
  neuron_signature:'',
  eth_signature:'',
  neuron_address:'',
  ckb_claim_num:0,
  seeu_claim_num:0,
  signature:null,
  showSign:null

};
