import React, {useEffect, useState} from 'react'
import {isMobile} from "is-mobile";



const Development = () => {
    const is_mobile = isMobile();
  return (
    <div className={is_mobile ? "about-main ph-main-mobile" : "about-main"}>
        <div className={is_mobile ? "development-title development-title-mobile": "development-title"}>
            Protocol developers
        </div>
        <div className={is_mobile ? "development-desc development-desc-mobile" : "development-desc"}>
            <div>
                Currently, Unicorn is built on the Spore Dob-0 protocol. But this protocol is primary, only allowing creators to build a one-to-one correspondence between DNA and Traits. Protocol developers can extend the Dob0 protocol and introduce more complex relations between DNA, Traits and Decoder to create more complex digital objects that conform to the Unicorn worldview.
            </div>

        </div>
        <div className={is_mobile ? "development-title development-title-mobile": "development-title development-margin"}>
            Application Developers
        </div>
        <div className={is_mobile ? "development-desc development-desc-mobile" : "development-desc"}>
            <div>
                There are many things that application developers can do to expand Unicorn’s world based on the setting collection. Profitable and non-profitable projects are both welcome. For example: Games, especially text Mud games; Depin, building a fully on-hand Unicorn Depin based on CKB's RISC-V instruction set; Social applications and governance applications when the community grow bigger.
            </div>
        </div>
        <div className={is_mobile ? "development-title development-title-mobile": "development-title development-margin"}>
            Creator
        </div>
        <div className={is_mobile ? "development-desc development-desc-mobile" : "development-desc"}>
            <div>
                There are some suggestions for creators:-Setting Collection. You can create new DOBs to expand the Unicorn setting collection.
                </div>
                <div>
                    -Visual Creation. Unicorn only has a DNA description but no visual image. Try to create a visual identity for them!
                </div>
            <div>
                -Merch Production. Peripheral productions about digital life, de-anthropocentrism, autonomous worlds, and digital nature are encouraged.
            </div>
        </div>
    </div>

  )
}

export default Development