

const FlipcardMobile = ({ front, back, yinyang, attributes }) => {

    return (
        <>
            <div className={'flip-box-m ' + yinyang}>
                <div className={'flip-front ' + attributes}>
                    {front}
                </div>
            </div>
        </>
    )
}
export default FlipcardMobile