import React, {useEffect, useState} from 'react'
import {isMobile} from "is-mobile";



const Philosophy = () => {
    const is_mobile = isMobile();


  return (
    <div className={is_mobile ? "about-main ph-main-mobile" : "about-main"}>
        {is_mobile && (<div className="ph-img">
            <img src="pm.png" alt=""/>
        </div>)}
        <div className={is_mobile ? "ph-title ph-title-mobile" : "ph-title"}>
            Philosophy
        </div>
        <div className={is_mobile ? "ph-desc ph-desc-mobile" : "ph-desc"}>
            <div>We want real digital life, not NFT. Through the Spore Dob protocol, we believe we are gradually approaching this dream.
            </div>
            <div>
                Real digital life means that it has a solid, unmodifiable existence. There is an essential provision for its life, which is not controlled by centralized platforms, such as Blizzard and TikTok. And it won’t be affected by platform protocol upgrades, such as Ethereum and IPFS. Once it is created, it exists by itself.
            </div>
            <div>
                We believe that the essence of life is simplicity. In the digital world, pictures are just a manifestation that humans can project emotions, but not the essence of life. Underneath the surface, there should be a deeper logic and simpler information to define the nature of existence, which we call the DNA of digital objects. The program that can interpret the DNA of digital objects into human-readable content is called Decoder. The DNA and Decoder together decide the nature  of digital objects, which should be stored on the blockchain, with security equivalent to that of the blockchain.
                The applications built based on the blockchain, such as wallets and games, should become the manifestation layer of digital objects. There, humans can introduce various ways to enable interactions between digital objects and humans, such as the memory system and soul system of external digital life.
            </div>
            <div>
                Therefore, we can make CKB a solid land for the birth of digital life, just as Bitcoin provide a solid land for the birth of digital gold. Therefore, we can create such a world: a de-anthropocentrism world, a world where humans can be creators but cannot be masters, a world without masters. Based on the simple structure and solid fundamental laws of the world, Human co-evolute with many existence, and the world evolve by emergence rather than design.
            </div>

        </div>
    </div>

  )
}

export default Philosophy