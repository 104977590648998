import React from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home";
import About from "./components/About";
import Philosophy from "./components/Philosophy";
import Community from "./components/Community";
import Development from "./components/Development";
import Setting from "./components/Setting";
import { initConfig } from "@joyid/ckb";

import {PersistGate} from "redux-persist/integration/react";
import store,{persistor} from "./store";
import { Provider } from "react-redux";
import {isMobile} from "is-mobile";
import {
    QueryClientProvider,
    QueryClient,
} from "@tanstack/react-query";

initConfig({
    name: "Unicorn",
    logo: "https://unidob.vercel.app",
    joyidAppURL: "https://app.joy.id",
});

const queryClient = new QueryClient();

function App() {
    const getQueryVariable = (variable) =>
    {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (let i=0;i<vars.length;i++) {
            let pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
        }
        return false;
    }
    console.log(getQueryVariable('tab'));
  return (
          <QueryClientProvider client={queryClient}>
                  <PersistGate loading={null} persistor={persistor} >
                      <Provider store={store}>
                          <div>
                              {isMobile() ? (<div>

                                  {getQueryVariable('tab') === false && (<div>
                                      <img src="4.png" style={{
                                          width:'100%'}} alt=""/>
                                      <Navbar />
                                      <Home />
                                  </div>)}

                                  {getQueryVariable('tab') === 'about' && (<div className="bg-no-repeat bg-center h-[60rem] main-bg about-bg" style={{
                                      backgroundImage: 'url(/about-m.png)',backgroundPosition: 'bottom',minHeight:'100vh',backgroundSize:'100% auto'}}>
                                      <Navbar />
                                      <About />
                                  </div>)}
                                  {getQueryVariable('tab') === 'philosophy' && (<div className="bg-no-repeat bg-center h-[60rem] main-bg philosophy-bg" style={{
                                      backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3))'
                                      }}>
                                      <Navbar />
                                      <Philosophy />
                                  </div>)}
                                  {getQueryVariable('tab') === 'community' && (<div className="bg-no-repeat bg-center h-[60rem] main-bg community-bg" style={{
                                      backgroundImage: 'url(/cm.png)',backgroundPosition: 'center',minHeight:'100vh',backgroundSize:'100% auto'
                                  }}>
                                      <Navbar />
                                      <Community />
                                  </div>)}
                                  {getQueryVariable('tab') === 'development' && (<div className="bg-no-repeat bg-center h-[60rem] main-bg development-bg" style={{
                                      backgroundImage: 'url(/dm.png)',backgroundRepeat:'no-repeat',backgroundPositionY:'150px',backgroundSize:'100% auto'
                                  }}>
                                      <Navbar />
                                      <Development />
                                  </div>)}
                                  {getQueryVariable('tab') === 'setting' && (<div className="bg-no-repeat bg-center h-[60rem] main-bg development-bg" style={{}}>
                                      <Navbar />
                                      <Setting />
                                  </div>)}
                              </div>) : (<div>
                                  {getQueryVariable('tab') === false && (<div className="bg-no-repeat bg-center h-[60rem] main-bg" style={{
                                      backgroundImage: 'url(/30.png)',backgroundSize:'100% auto'}}>
                                      <Navbar />
                                      <Home />
                                  </div>)}
                                  {getQueryVariable('tab') === 'about' && (<div className="bg-no-repeat bg-center h-[60rem] main-bg about-bg" style={{
                                      backgroundImage: 'url(/about.png)',backgroundSize:'50% auto'}}>
                                      <Navbar />
                                      <About />
                                  </div>)}
                                  {getQueryVariable('tab') === 'philosophy' && (<div className="bg-no-repeat bg-center h-[60rem] main-bg philosophy-bg" style={{
                                      backgroundImage: 'linear-gradient(rgba(0, 0, 0, 0.3),rgba(0, 0, 0, 0.3)),url(/philosophy1.png)',backgroundSize:'100% auto'}}>
                                      <Navbar />
                                      <Philosophy />
                                  </div>)}
                                  {getQueryVariable('tab') === 'community' && (<div className="bg-no-repeat bg-center h-[60rem] main-bg community-bg" style={{
                                      backgroundImage: 'url(/c.png)',backgroundRepeat:'no-repeat',backgroundPosition: 'center',backgroundSize:'50% auto'
                                  }}>
                                      <Navbar />
                                      <Community />
                                  </div>)}
                                  {getQueryVariable('tab') === 'development' && (<div className="bg-no-repeat bg-center h-[60rem] main-bg development-bg" style={{
                                      backgroundImage: 'url(/d.png)',backgroundRepeat:'no-repeat',backgroundPosition: 'center',backgroundSize:'100% auto'
                                  }}>
                                      <Navbar />
                                      <Development />
                                  </div>)}
                                  {getQueryVariable('tab') === 'setting' && (<div className="bg-no-repeat bg-center h-[60rem] main-bg development-bg" style={{}}>
                                      <Navbar />
                                      <Setting />
                                  </div>)}
                              </div>)}
                          </div>
                      </Provider>
                  </PersistGate>
          </QueryClientProvider>

  );
}

export default App;
