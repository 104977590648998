import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { shortAddress } from "../utils/global";
import { connect, signRawTransaction, SignConfig } from '@joyid/ckb';
import { RPC, Transaction } from '@ckb-lumos/lumos';
import { predefinedSporeConfigs } from '@spore-sdk/core';
import { List, Map as ImmutableMap, Record } from "immutable";
import { createTransactionFromSkeleton } from '@ckb-lumos/lumos/helpers';
import ClaimSuccessPopup from "./ClaimSuccessPopup";
import Firefly from './Firefly'
import { isMobile } from "is-mobile";
import store from "../store";
import { saveJoyid } from "../store/reducer";
import { useSelector } from "react-redux";
import Flipcard from './Flipcard';
import FlipcardMobile from './FlipcardMobile';

const CKB_RPC_URL = "https://mainnet.ckb.dev/rpc"

const rpc = new RPC(CKB_RPC_URL)


const Btn = styled.button`
        background: #0CFADE;
    color:#000000;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    border-radius: 16px;
    margin: 20px auto 0;
    display: flex;
    gap:8px;
    width: 232px;
    height: 56px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
   &:disabled{
        opacity: 0.6;
       cursor: not-allowed;
   }
`

const Btn2 = styled.button`
        background: #A028E9;
    color:white;

    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    border-radius: 16px;
    margin: 20px auto 0;
    display: flex;
    width: 232px;
    height: 56px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap:12px;
   &:disabled{
        opacity: 0.6;
       cursor: not-allowed;
   }
`

const Btn4 = styled.button`
        background: #A028E9;
    color:white;

    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    border-radius: 5px;
    display: flex;
    width: 180px;
    height: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap:12px;
   &:disabled{
        opacity: 0.6;
       cursor: not-allowed;
   }
`

const Btn3 = styled.button`
        background: #0CFADE;
    color:#000000;

    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    border-radius: 5px;
    margin: 20px auto 0;
    display: flex;
    gap:8px;
    width: 175px;
    height: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
   &:disabled{
        opacity: 0.6;
       cursor: not-allowed;
   }
`

const Btn1 = styled.button`
    background: #0CFADE;
    color:#000000;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    border-radius: 16px;
    margin: 12px auto 0;
    display: flex;
    width: 232px;
    height: 56px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
   &:disabled{
        opacity: 0.6;
       cursor: not-allowed;
   }
`

const Btn5 = styled.button`
    background: #0CFADE;
    color:#000000;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    border-radius: 5px;
    display: flex;
    width: 180px;
    height: 30px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
   &:disabled{
        opacity: 0.6;
       cursor: not-allowed;
   }
`


const Home = () => {
    const is_mobile = isMobile();
    const sporeConfig =predefinedSporeConfigs.Aggron4;
    const joyid_account = useSelector(store => store.joyid_account);
    const [count, setCount] = useState(0);
    const [flag, setFlag] = useState(false);
    const [boxes, setBoxes] = useState('');
    const [loading, setLoading] = useState(false);
    const [showSuccessPopup, setShowSuccessPopup] = useState(false);
    const handleCloseSuccess = () => {
        setShowSuccessPopup(false);
    };
    const handleOpenSuccess = () => {
        setShowSuccessPopup(true);
    };


    const onConnect = async () => {
        try {
            const authData = await connect();
            // setJoyid(authData.address);
            store.dispatch(saveJoyid(authData.address));
        } catch (error) {
            console.error('onConnect error', error);
        }
    }

    const getBoxes = async () => {
        if (!joyid_account) {
            return;
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const data = new FormData();
        data.append('address', joyid_account);

        var requestOptions = {
            method: 'POST',
            body: data,
        };

        fetch("https://seeu-nft-rest.matrixlabs.org/nfts/boxes/open", requestOptions)
            .then(response => response.text())
            .then(result => {
                const res = JSON.parse(result);
                if (res && res.data && res.data.txSkeleton) {
                    setBoxes(JSON.stringify(res.data.txSkeleton))
                    setCount(res.data.leftBoxes)
                }
            })
            .catch((error) => {
                console.log('getBoxes error', error);
            });
    }

    const getBalance = async () => {
        if (!joyid_account) {
            return;
        }
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");


        var requestOptions = {
            method: 'GET',
            headers: { Accept: 'application/vnd.api+json', 'Content-Type': 'application/vnd.api+json' }
        };

        fetch("https://mainnet-api.explorer.nervos.org/api/v1/addresses/" + joyid_account, requestOptions)
            .then(response => response.text())
            .then(res => {
                const result = JSON.parse(res);
                console.log('getBalance', result,result.data[0].attributes.balance - result.data[0].attributes.balance_occupied);
                if (result && result.data && result.data[0] && result.data[0].attributes && result.data[0].attributes.balance && (result.data[0].attributes.balance - result.data[0].attributes.balance_occupied >= 6500000000)) {
                    setFlag(true);
                } else {
                    setFlag(false);
                }
            })
            .catch((error) => {
                console.log('getBalance error', error);
            });
    }

    useEffect(() => {
        if (joyid_account) {
            getBoxes();
            getBalance();
        } else {
            setCount(0);
        }
    }, [joyid_account]);

    const transferSpore =
        async () => {
            const res = JSON.parse(boxes);
            const TransactionSkeleton = Record({
                cellProvider: null,
                cellDeps: List(),
                headerDeps: List(),
                inputs: List(),
                outputs: List(),
                witnesses: List(),
                fixedEntries: List(),
                signingEntries: List(),
                inputSinces: ImmutableMap(),
            });
            let txSkeleton = TransactionSkeleton();

            txSkeleton = txSkeleton.update('outputs', (outputs) => outputs.push(...res.outputs));
            txSkeleton = txSkeleton.set('cellProvider', res.cellProvider);
            txSkeleton = txSkeleton.update('cellDeps', (cellDeps) => cellDeps.push(...res.cellDeps));
            txSkeleton = txSkeleton.update('headerDeps', (headerDeps) => headerDeps.push(...res.headerDeps));
            txSkeleton = txSkeleton.update('inputs', (inputs) => inputs.push(...res.inputs));
            txSkeleton = txSkeleton.update('witnesses', (witnesses) => witnesses.push(...res.witnesses));
            txSkeleton = txSkeleton.update('fixedEntries', (fixedEntries) => fixedEntries.push(...res.fixedEntries));
            txSkeleton = txSkeleton.update('signingEntries', (signingEntries) => signingEntries.push(...res.signingEntries));
            try {
                let tx = createTransactionFromSkeleton(txSkeleton);
                const signConfig = { 'witnessIndex': 1 };
                console.log('createTransactionFromSkeleton', tx, joyid_account);
                const signedTx = await signRawTransaction(tx, joyid_account, signConfig);
                console.log('signRawTransaction', signedTx);
                const txHash = await sendTransaction(signedTx);
                console.log('sendTransaction', txHash);
                const timeout = setInterval(
                    () => {
                        getFlag(txHash).then((res) => {
                            console.log('getFlag', res);
                            if (res) {
                                clearInterval(timeout);
                                // Claim(txHash,3).then(re => {
                                // })
                                handleOpenSuccess();
                                const c = count - 1;
                                setCount(c);
                                // getBoxes();
                                getBalance();
                            }
                        })
                    }
                    , 2000);
            } catch (e) {
                console.log('alert error',e);
                alert('Please wait for on-chain confirmation. If this message appears multiple times, please refresh the page.' + e);
            }
        };
    const getFlag = async (hash) => {
        return await rpc.getTransaction(hash).then((res) => {
            console.log('result', res.txStatus.status, hash);
            if (res.txStatus.status === "committed") {
                setLoading(false);
                return true;
            } else {
                return false;
            }

        })

    }

    const onSign = async () => {
        try {
            if (!boxes) {
                alert('please wait!');
                return;
            }
            if (!flag) {
                alert('please make sure you have at least 65 ckb');
                return;
            }
            setLoading(true);
            transferSpore().then(res=> {
                console.log('onSign',res);
            });
        } catch (error) {
            console.error('onSign error', error);
        }
    }



    const sendTransaction = async (tx) => {
        const rpc = new RPC(CKB_RPC_URL);
        const hash = await rpc.sendTransaction(tx, 'passthrough');
        return hash;
    }

    return (
        <div>
            <div className={is_mobile ? "flex justify-center items-center home-bg" : "w-full flex justify-center items-center mt-5 p-20"}>
                <Firefly />
                <div className={is_mobile ? "flex" : "w-[80rem] h-[40rem] flex"}>
                    {is_mobile ? (<div></div>) : (<div className="w-[18rem] md:w-[40rem] lg:w-[40rem]">

                    </div>)}

                    <div className={is_mobile ? " mt-3 text-[#FFFFFF]" : " mt-20 text-[#FFFFFF]"}>
                        <div className={is_mobile ? "home-top-font home-top-font-mobile" : "home-top-font"}>
                            {!is_mobile ? (<span>The First DOBs on CKB</span>) : (<span>The First DOBs on CKB</span>)}
                        </div>
                        <div className={is_mobile ? "home-top-margin-mobile" : "home-top-margin"}>
                            {!joyid_account ? (is_mobile ? (<Btn3 onClick={() => onConnect()}><img className="home-button-img-mobile" src="joy.png" alt="" /> <span className="home-top-button">Connect JoyID</span></Btn3>) :
                                (<Btn onClick={() => onConnect()}><img className="home-button-img" src="joy.png" alt="" /> <span className="home-top-button">Connect JoyID</span></Btn>)) :
                                (is_mobile ? (<div>
                                    <div style={{ display: 'flex', gap: '10px', flexWrap: 'wrap', justifyContent: 'center' }}>
                                        <Btn4 ><span className="home-top-button">{shortAddress(joyid_account)}</span><img
                                            onClick={() => store.dispatch(saveJoyid(''))}
                                            style={{ cursor: 'pointer', width: '15px' }}
                                            src="disconnect1.png" alt="" /></Btn4>
                                        <Btn5 onClick={() => onSign()} disabled={count <= 0 || loading} ><span>Reveal Unicorn DNA</span></Btn5>
                                    </div>
                                </div>
                                ) : (<div>
                                    <Btn2 ><span className="home-top-button">{shortAddress(joyid_account)}</span><img
                                        onClick={() => store.dispatch(saveJoyid(''))}
                                        style={{ cursor: 'pointer', width: '15px' }}
                                        src="disconnect1.png" alt="" /></Btn2>
                                        <div>
                                            {count > 0 ? (
                                                <div className="hone-button-middle">
                                                    {count} unicorn Box available
                                                </div>) : (
                                                <div className="hone-button-middle">
                                                    All Unicorn Boxes have been successfully revealed
                                                </div>)}
                                            <Btn1 onClick={() => onSign()} disabled={count <= 0 || loading} >{loading ? (<span>Revealing Unicorn DNA</span>) : (<span>Reveal Unicorn DNA</span>)}</Btn1>
                                        </div>
                                </div>
                                ))}
                            <ClaimSuccessPopup showPopup={showSuccessPopup} claimType={'neuron'} close={handleCloseSuccess} />
                        </div>
                    </div>
                </div>

            </div>
            {is_mobile ? (<div className="bottom-home"
            >
                <div className="bottom-title-mobile">
                    Magic Unicorns
                </div>
                <div
                    className="bottom-home"
                    style={{
                        backgroundImage: 'url(/bottom-img.png)', backgroundSize: '50% auto', display: 'flex',
                        justifyContent: 'center'
                    }}
                >
                    <div className="bottom-card-main-mobile">
                        <div className="bottom-child">
                            <FlipcardMobile front={
                                <ul>
                                    <li>Water, White Body</li>
                                    <li>Yang, Short Hair</li>
                                    <li>Summon</li>
                                    <li>Bard Horn</li>
                                    <li>Spring Wings</li>
                                    <li>Meteor Tail</li>
                                    <li>Lotus Horseshoes</li>
                                    <li>67880</li>
                                    <li>33</li>
                                </ul>
                            } back="potion.png" yinyang="yang" attributes="water" />
                        </div>
                        <div className="bottom-child">
                            <FlipcardMobile
                                yinyang="yin" attributes="water"
                                front={
                                    <ul>
                                        <li>Water, White Body</li>
                                        <li>Yin, Long Hair</li>
                                        <li>Forget</li>
                                        <li>Lethe Horn</li>
                                        <li>Angel Wings 🪽</li>
                                        <li>Gaia Tail</li>
                                        <li>Silver Horseshoes</li>
                                        <li>56880</li>
                                        <li>32</li>
                                    </ul>
                                } back="river.png" />

                        </div>
                        <div className="bottom-child">
                            <FlipcardMobile
                                yinyang="yang" attributes="fire"
                                front={
                                    <ul>
                                        <li>Fire, Red Body</li>
                                        <li>Yang, Short Hair</li>
                                        <li>Prophet</li>
                                        <li>Sibyl Horn</li>
                                        <li>Sun Wings</li>
                                        <li>Dumbledore Tail</li>
                                        <li>Maple Horseshoes</li>
                                        <li>55580</li>
                                        <li>31</li>
                                    </ul>
                                } back="sun.png" />
                        </div>
                        <div className="bottom-child">
                            <FlipcardMobile
                                yinyang="yin" attributes="fire"
                                front={
                                    <ul>
                                        <li>Fire, Red Body</li>
                                        <li>Yin, Long Hair</li>
                                        <li>Curse</li>
                                        <li>Necromancer Horns</li>
                                        <li>Lightning Wings</li>
                                        <li>Rainbow Tail</li>
                                        <li>Ice Horseshoes</li>
                                        <li>78910</li>
                                        <li>30</li>
                                    </ul>
                                } back="flash.png" />
                        </div>
                        <div className="bottom-child">
                        </div>

                        <div className="bottom-child">
                            <FlipcardMobile
                                yinyang="yang" attributes="wood"
                                front={
                                    <ul>
                                        <li>Wood, Blue Body</li>
                                        <li>Yang, Short Hair</li>
                                        <li>Revival</li>
                                        <li>Shaman Horn</li>
                                        <li>Spring Wings</li>
                                        <li>Phoenix Tail</li>
                                        <li>Flame Horseshoes</li>
                                        <li>87650</li>
                                        <li>22</li>
                                    </ul>
                                } back="olive.png" />

                        </div>
                        <div className="bottom-child">
                            <FlipcardMobile
                                yinyang="Yin" attributes="wood"
                                front={
                                    <ul>
                                        <li>Wood, Blue Body</li>
                                        <li>Yin, Long Hair</li>
                                        <li>Death</li>
                                        <li>Hel Horn</li>
                                        <li>Night Shadow Wings</li>
                                        <li>Willow Tail</li>
                                        <li>Ice Horseshoes</li>
                                        <li>59580</li>
                                        <li>26</li>
                                    </ul>
                                } back="bone.png" />
                        </div>
                        <div className="bottom-child">
                        </div>

                        <div className="bottom-child">
                            <FlipcardMobile
                                yinyang="yang" attributes="metal"
                                front={
                                    <ul>
                                        <li>Metal, Golden Body</li>
                                        <li>Yang, Short Hair</li>
                                        <li>Attack</li>
                                        <li>Warrior Horn</li>
                                        <li>Angel Wings🪽</li>
                                        <li>Phoenix Tail</li>
                                        <li>Thunder Horseshoes</li>
                                        <li>64530</li>
                                        <li>21</li>
                                    </ul>
                                } back="sword.png" />
                        </div>
                        <div className="bottom-child">
                            <FlipcardMobile
                                yinyang="yin" attributes="metal"
                                front={
                                    <ul>
                                        <li>Metal, Golden Body</li>
                                        <li>Yin, Long Hair</li>
                                        <li>Guard</li>
                                        <li>Praetorian Horn</li>
                                        <li>Morning Glow Wings</li>
                                        <li>Venus Tail</li>
                                        <li>Golden Horseshoes</li>
                                        <li>89029</li>
                                        <li>13</li>
                                    </ul>
                                } back="shield.png" />
                        </div>
                        <div className="bottom-child">
                            <FlipcardMobile
                                yinyang="yang" attributes="earth"
                                front={
                                    <ul>
                                        <li>Earth, Colorful Body</li>
                                        <li>Yang, Long Hair</li>
                                        <li>Crown</li>
                                        <li>Caesar Horn</li>
                                        <li>Golden Wings</li>
                                        <li>Sunset Shadow Tail</li>
                                        <li>Lotus Horseshoes</li>
                                        <li>77770</li>
                                        <li>5</li>
                                    </ul>
                                } back="crown.png" />
                        </div>
                        <div className="bottom-child">
                            <FlipcardMobile
                                yinyang="Yin" attributes="earth"
                                front={
                                    <ul>
                                        <li>Earth, Colorful Body</li>
                                        <li>Yin, Long Hair</li>
                                        <li>Hermit</li>
                                        <li>Lao Tzu Horn</li>
                                        <li>Cloud Wings</li>
                                        <li>Socrates Tail</li>
                                        <li>Crystal Horseshoes</li>
                                        <li>63780</li>
                                        <li>12</li>
                                    </ul>
                                } back="robe.png" />
                        </div>
                    </div>

                </div>
            </div>) : (<div className='bottom-main'>
                <div className="bottom-title">
                    <div>
                        Magic Unicorns
                    </div>
                    <div>
                        Put the seed on chain, expand it in the world.
                    </div>
                </div>
                <div className="bottom-card-main">
                    <div className="bottom-child">
                    <Flipcard front={
                                <ul>
                                    <li>Water, White Body</li>
                                    <li>Yang, Short Hair</li>
                                    <li>Summon</li>
                                    <li>Bard Horn</li>
                                    <li>Spring Wings</li>
                                    <li>Meteor Tail</li>
                                    <li>Lotus Horseshoes</li>
                                    <li>67880</li>
                                    <li>33</li>
                                </ul>
                            } back="potion.png" yinyang="yang" attributes="water" />
                    </div>
                    <div className="bottom-child">
                        <Flipcard
                            yinyang="yin" attributes="water"
                            front={
                                <ul>
                                    <li>Water, White Body</li>
                                    <li>Yin, Long Hair</li>
                                    <li>Forget</li>
                                    <li>Lethe Horn</li>
                                    <li>Angel Wings 🪽</li>
                                    <li>Gaia Tail</li>
                                    <li>Silver Horseshoes</li>
                                    <li>56880</li>
                                    <li>32</li>
                                </ul>
                            } back="river.png" />
                    </div>
                    <div className="bottom-child">
                        <Flipcard
                            yinyang="yang" attributes="fire"
                            front={
                                <ul>
                                    <li>Fire, Red Body</li>
                                    <li>Yang, Short Hair</li>
                                    <li>Prophet</li>
                                    <li>Sibyl Horn</li>
                                    <li>Sun Wings</li>
                                    <li>Dumbledore Tail</li>
                                    <li>Maple Horseshoes</li>
                                    <li>55580</li>
                                    <li>31</li>
                                </ul>
                            } back="sun.png" />
                    </div>
                    <div className="bottom-child">
                        <Flipcard
                            yinyang="yin" attributes="fire"
                            front={
                                <ul>
                                    <li>Fire, Red Body</li>
                                    <li>Yin, Long Hair</li>
                                    <li>Curse</li>
                                    <li>Necromancer Horn</li>
                                    <li>Lightning Wings</li>
                                    <li>Rainbow Tail</li>
                                    <li>Ice Horseshoes</li>
                                    <li>78910</li>
                                    <li>30</li>
                                </ul>
                            } back="flash.png" />
                    </div>
                    <div className="bottom-child">
                        <Flipcard
                            yinyang="yang" attributes="wood"
                            front={
                                <ul>
                                    <li>Wood, Blue Body</li>
                                    <li>Yang, Short Hair</li>
                                    <li>Revival</li>
                                    <li>Shaman Horn</li>
                                    <li>Spring Wings</li>
                                    <li>Phoenix Tail</li>
                                    <li>Flame Horseshoes</li>
                                    <li>87650</li>
                                    <li>22</li>
                                </ul>
                            } back="olive.png" />

                    </div>
                    <div className="bottom-child">
                        <Flipcard
                            yinyang="Yin" attributes="wood"
                            front={
                                <ul>
                                    <li>Wood, Blue Body</li>
                                    <li>Yin, Long Hair</li>
                                    <li>Death</li>
                                    <li>Hel Horn</li>
                                    <li>Night Shadow Wings</li>
                                    <li>Willow Tail</li>
                                    <li>Ice Horseshoes</li>
                                    <li>59580</li>
                                    <li>26</li>
                                </ul>
                            } back="bone.png" />
                    </div>
                    <div className="bottom-child">
                        <Flipcard
                            yinyang="yang" attributes="metal"
                            front={
                                <ul>
                                    <li>Metal, Golden Body</li>
                                    <li>Yang, Short Hair</li>
                                    <li>Attack</li>
                                    <li>Warrior Horn</li>
                                    <li>Angel Wings🪽</li>
                                    <li>Phoenix Tail</li>
                                    <li>Thunder Horseshoes</li>
                                    <li>64530</li>
                                    <li>21</li>
                                </ul>
                            } back="sword.png" />
                    </div>
                    <div className="bottom-child">
                        <Flipcard
                            yinyang="yin" attributes="metal"
                            front={
                                <ul>
                                    <li>Metal, Golden Body</li>
                                    <li>Yin, Long Hair</li>
                                    <li>Guard</li>
                                    <li>Praetorian Horn</li>
                                    <li>Morning Glow Wings</li>
                                    <li>Venus Tail</li>
                                    <li>Golden Horseshoes</li>
                                    <li>89029</li>
                                    <li>13</li>
                                </ul>
                            } back="shield.png" />
                    </div>
                    <div className="bottom-child">
                        <Flipcard
                            yinyang="yang" attributes="earth"
                            front={
                                <ul>
                                    <li>Earth, Colorful Body</li>
                                    <li>Yang, Long Hair</li>
                                    <li>Crown</li>
                                    <li>Caesar Horn</li>
                                    <li>Golden Wings</li>
                                    <li>Sunset Shadow Tail</li>
                                    <li>Lotus Horseshoes</li>
                                    <li>77770</li>
                                    <li>5</li>
                                </ul>
                            } back="crown.png" />
                    </div>
                    <div className="bottom-child">
                        <Flipcard
                            yinyang="Yin" attributes="earth"
                            front={
                                <ul>
                                    <li>Earth, Colorful Body</li>
                                    <li>Yin, Long Hair</li>
                                    <li>Hermit</li>
                                    <li>Lao Tzu Horn</li>
                                    <li>Cloud Wings</li>
                                    <li>Socrates Tail</li>
                                    <li>Crystal Horseshoes</li>
                                    <li>63780</li>
                                    <li>12</li>
                                </ul>
                            } back="robe.png" />
                    </div>

                </div>
            </div>)}
        </div>

    )
}

export default Home