import React from 'react'
import {isMobile} from "is-mobile";
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';


const Navbar = () => {
    const [open, setOpen] = React.useState(false);

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
            <List>
                <ListItem key="About" onClick={()=>window.location.href="/?tab=about"} disablePadding>
                    <ListItemButton>
                        <ListItemText primary="About" />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Philosophy" onClick={()=>window.location.href="/?tab=philosophy"} disablePadding>
                    <ListItemButton>
                        <ListItemText primary="Philosophy" />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Setting Collection" onClick={()=>window.location.href="/?tab=setting"} disablePadding>
                    <ListItemButton>
                        <ListItemText primary="Setting Collection" />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Community" onClick={()=>window.location.href="/?tab=community"} disablePadding>
                    <ListItemButton>
                        <ListItemText primary="Community" />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Development" onClick={()=>window.location.href="/?tab=development"} disablePadding>
                    <ListItemButton>
                        <ListItemText primary="Development" />
                    </ListItemButton>
                </ListItem>
                <ListItem key="Playbook" onClick={()=>window.location.href="https://unicorns-organization.gitbook.io/unicorn-cao-zuo-shou-ce"} disablePadding>
                    <ListItemButton>
                        <ListItemText primary="Playbook" />
                    </ListItemButton>
                </ListItem>
            </List>

        </Box>
    );
    const getTab = (variable) =>
    {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (let i=0;i<vars.length;i++) {
            let pair = vars[i].split("=");
            if(pair[0] == variable){return pair[1];}
        }
        return false;
    }

    return (
        <div className={isMobile() ? "w-full flex justify-center items-center navbar-main navbar-main-mobile" : "w-full flex justify-center items-center navbar-main"}>
            <div className={isMobile() ? "w-[24rem] md:w-[48rem] lg:w-[64rem] h-15  items-center navbar-main-mobile-new" : "w-[24rem] md:w-[48rem] lg:w-[64rem] h-15 flex  items-center "}>
                {isMobile() && (<div onClick={toggleDrawer(true)} style={{flex:1,cursor:'pointer'}}>
                    <img src="em.png" style={{width:'20px',marginLeft:'10px'}} alt=""/>
                </div>)}
                <a  style={{flex:1}} href="/">
                    <div className=" flex  items-center ">
                        <img style={{width:'25px'}} src="loog.png" alt="buttonpng"  />
                        <div className=" font-bold	text-2xl	 p-2.5 text-center inline-flex items-center me-2">
                            Unicorn
                        </div>
                    </div>
                </a>
                <div className="w-[24rem] md:w-[48rem] lg:w-[64rem] h-15 flex flex-row-reverse items-center navbar-rm">
                    <a href={'https://twitter.com/unicorn_dobs'} className={isMobile() ? "Navbar-icon-btn-right float-right font-bold py-3 pl-3 rounded-full" : "Navbar-icon-btn-right ml-4 float-right font-bold py-3 rounded-full"}>
                        <img src="/xx.png" alt="buttonpng" border="0" width={24} height={24}  />
                    </a>

                    <a href={'https://t.me/unicorn_ckb'} className={isMobile() ? "Navbar-icon-btn-right float-right font-bold py-3 pl-3 rounded-full" : "Navbar-icon-btn-right ml-4 float-right font-bold py-3 rounded-full"}>
                        <img src="/tgtg.png" alt="buttonpng" border="0" width={24} height={24}  />
                    </a>
                    {!isMobile() && (<div className="flex navbar-title">
                        <a href="/?tab=about"><div className={getTab('tab') === 'about' ? 'navbar-active': ''}>
                            About
                        </div></a>
                        <a href="/?tab=philosophy"><div className={getTab('tab') === 'philosophy' ? 'navbar-active': ''}>
                            Philosophy
                        </div></a>
                        <a href="/?tab=setting"><div className={getTab('tab') === 'setting' ? 'navbar-active': ''}>
                            Setting Collection
                        </div></a>
                        <a href="/?tab=community"><div className={getTab('tab') === 'community' ? 'navbar-active': ''}>
                            Community
                        </div></a>
                        <a href="/?tab=development"><div className={getTab('tab') === 'development' ? 'navbar-active': ''}>
                            Development
                        </div></a>
                        <a href="https://unicorns-organization.gitbook.io/unicorn-cao-zuo-shou-ce"><div>
                            Playbook
                        </div></a>
                    </div>)}
                </div>
            </div>
            <Drawer open={open} onClose={toggleDrawer(false)}>
                {DrawerList}
            </Drawer>
        </div>
    )
}

export default Navbar
