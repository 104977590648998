import React, { useEffect, useState } from 'react'
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const FireFly = () => {
    const [init, setInit] = useState(false);
    const particlesOption = {

        fpsLimit: 120,
        interactivity: {
            events: {
                onClick: {
                    enable: true,
                    mode: "push",
                },
                onHover: {
                    enable: true,
                    mode: "repulse",
                },
             
            },
            modes: {
                push: {
                    quantity: 4,
                },
                repulse: {
                    distance: 100,
                    duration: 0.1,
                },
            },
        },
        particles: {
            color: {
                value: "#ffffff",
            },
            links: {
                enable: false,
            },
            move: {
                direction: "none",
                enable: true,
                outModes: {
                    default: "bounce",
                },
                random: true,
                speed: 3,
                straight: true,
            },
            number: {
                density: {
                    enable: true,
                    area: 800,
                },
                value: 80,
            },
            opacity: {
                value: {
                  min: 0.8,
                  max: 1
                },
                animation: {
                  enable: true,
                  speed: 3
                }
              },
            shape: {
                type: "circle",
            },
          
            size: {
                value: {
                    min: 3,
                    max: 5
                  },
                  animation: {
                    enable: true,
                    speed: 1
                  }
            },
        },
        detectRetina: true,
    }
    useEffect(() => {
        initParticlesEngine(async (engine) => {

            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    return (
        <>
            {init && <Particles
                id="tsparticles"
                particlesLoaded={particlesLoaded}
                options={particlesOption}
            />}
        </>
    )
}
export default FireFly